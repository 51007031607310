import { getDifferenceInYearsAndDaysString } from '../../utils/Date.Util';

export const LICENSE_STATUS = {
  ACTIVE: 'active',
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
  EXPIRED: 'expired',
};

export const FOUR_G_STATUS = {
  YES: 'yes',
  NO: 'no',
};

export const durationTypeValues = {
  ONE_YEAR: 12,
  TWO_YEARS: 24,
  THREE_YEARS: 36,
  FOUR_YEARS: 48,
  FIVE_YEARS: 60,
};

export const DURATION_TYPES = {
  ONE_YEAR: 'one_year',
  TWO_YEARS: 'two_years',
  THREE_YEARS: 'three_years',
  FOUR_YEARS: 'four_years',
  FIVE_YEARS: 'five_years',
};

export const PORT_COUNT = {
  ONE: 1,
  TWO: 2,
};

export const licenseTypeValues = {
  LEVEL_2_SINGLE_PORT: 'STD L2 - single',
  LEVEL_2_DUAL_PORT: 'STD L2 - dual',
  DC_FAST_SINGLE_PORT: 'STD DCFC - single',
  DC_FAST_DUAL_PORT: 'STD DCFC - dual',
  ESSENTIALS_SINGLE_PORT: 'Essentials - single',
  ESSENTIALS_DUAL_PORT: 'Essentials - dual',
  ELA_L2_SINGLE_PORT: 'ELA L2 - single',
  ELA_L2_DUAL_PORT: 'ELA L2 - dual',
  ELA_DC_SINGLE_PORT: 'ELA DC - single',
  ELA_DC_DUAL_PORT: 'ELA DC - dual',
  HOME_HOME: 'Home',
};

export const LICENSE_TYPES = {
  LEVEL_2: 'level_2',
  DC_FAST: 'dc_fast',
  ESSENTIALS: 'essentials',
  ELA_L2: 'ela_l2',
  ELA_DC: 'ela_dc',
  HOME: 'home',
};

export const LICENSE_PORT_TYPES = {
  SINGLE_PORT: 'single_port',
  DUAL_PORT: 'dual_port',
};

export const FILTER_TYPES = {
  STATUS: 'status',
  DURATION: 'duration',
  TYPE: 'type',
  INCLUDES_4G: 'includes4G',
  CHARGER_ID: 'chargerId',
  SEARCH_VALUE: 'searchValue',
};

export const LICENSE_LEVEL_TYPES = {
  LEVEL_2: 'level 2',
  DC_FAST: 'level 3',
  ELA_L2: 'level 2',
  ELA_DC: 'level 3',
} as const;

type LicenseLevelType = keyof typeof LICENSE_LEVEL_TYPES;

export const getRemainingDays = (
  startDate: string | Date,
  endDate: string | Date,
) => {
  const { years, remainingDays: days } = getDifferenceInYearsAndDaysString(
    startDate,
    endDate,
  );

  return { years, days };
};

export const getReadableLicense = (value: string) => {
  const typeKey = Object.entries(licenseTypeValues)
    .find(([, val]) => val === value)?.[0]
    ?.toLowerCase();
  return (
    Object.entries(LICENSE_TYPES)
      .find(([, val]) => typeKey?.startsWith(val))?.[0]
      ?.toLowerCase() || value
  );
};

export const getInternalLicenseType = (value: string, ports?: number) => {
  const typeKey = Object.entries(LICENSE_TYPES)
    .find(([, val]) => val === value)?.[0]
    ?.toLowerCase();

  // Return the original value if typeKey is undefined
  if (!typeKey) {
    return value;
  }
  // Find the entry with matching typeKey
  const entries = Object.entries(licenseTypeValues).filter(
    ([key]) => key && key.toLowerCase().includes(typeKey),
  );

  // Return base type without port suffix for home/demo or when ports not specified
  if (!ports || [LICENSE_TYPES.HOME].includes(value)) {
    return entries[0]?.[1].split(' -')[0] || value;
  }

  const suffix =
    ports === PORT_COUNT.ONE
      ? LICENSE_PORT_TYPES.SINGLE_PORT
      : LICENSE_PORT_TYPES.DUAL_PORT;

  const typeKeyWithSuffix = typeKey.concat('_', suffix);

  // Find the entry with matching ports (single/dual)
  return (
    entries.find(
      ([key]) => key.toLowerCase() === typeKeyWithSuffix.toLowerCase(),
    )?.[1] || value
  );
};

export const daysDifferenceText = (
  isExpired: boolean,
  expDate: string | Date,
  t: any,
) => {
  const daysObj = isExpired
    ? getRemainingDays(expDate, new Date())
    : getRemainingDays(new Date(), expDate);

  const { years, days } = daysObj;

  const yearText = `${years}  ${t(years > 1 ? 'years' : 'year')}`;
  const dayText = `${days} ${t(days > 1 ? 'days' : 'day')}`;

  return years > 0 ? `${yearText} ${dayText}` : dayText;
};

export const isNoDurationType = (licenseType: string) => {
  const allowedPrefixes = ['ela', 'essential', 'home'];
  return allowedPrefixes.some((prefix) =>
    licenseType?.toLowerCase().startsWith(prefix),
  );
};

export const getLicenseLevelType = (licenseType: string): string => {
  const readableType = getReadableLicense(licenseType);
  const key = readableType.toUpperCase() as LicenseLevelType;
  return LICENSE_LEVEL_TYPES[key] || readableType;
};
