import { SCOPE_TYPE } from '../../services/utils';

export interface License {
  licenseId: string;
  licenseKey: string;
  orderId: string;
  status: string;
  assignedCharger?: string;
  type: string;
  includes4G: boolean;
  duration: number;
  companyId: string;
  creationDate: Date;
  activationDate?: Date;
  expirationDate?: Date;
  ports?: number;
  orderNote?: string;
}

export interface LicensePostRequest {
  type: string;
  includes4G: boolean;
  duration?: number;
  companyId: string;
  ports?: number;
  orderNote?: string;
  numberOfLicenses: number;
}

export interface LicenseListRequest {
  scope: SCOPE_TYPE;
  limit?: number;
  offset?: number;
  'filter_in[chargerId]'?: string;
  'filter_in[status]'?: string;
  'filter_in[duration]'?: string;
  'filter_in[type]'?: string;
  'filter_in[includes4G]'?: string;
  'filter_like[searchValue]'?: string;
}

export interface LicenseListResponse {
  totalCount: number;
  entities: Array<License>;
}

export enum LICENSE_STATUS {
  ACTIVE = 'ACTIVE',
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
  EXPIRED = 'EXPIRED',
}

export interface LicensePatchRequestBody {
  licenseKey: string;
  assignedCharger?: string;
  status: LICENSE_STATUS;
}

export interface LicensePatchRequestUrlParam {
  companyId: string;
  scope: SCOPE_TYPE;
  bulkOperation: boolean;
}

export interface AssignLicenseRequestToast {
  chargerName: string;
  isChargerDeployed: boolean;
}

export interface AssignLicenseRequest {
  licensePatchRequestBody: LicensePatchRequestBody;
  licensePatchRequestUrlParam: LicensePatchRequestUrlParam;
  licensePatchRequestToast: AssignLicenseRequestToast;
}

export interface LicenseAssignResponseObj {
  licenseKey: string;
  assignedCharger: string;
  status: LICENSE_STATUS;
}

export interface LicenseAssignResponse {
  error: boolean;
  response: LicenseAssignResponseObj;
}
